
import mixins from 'vue-typed-mixins';
import { SelectOption } from '@/types';

type Source = {
  options: SelectOption[];
  values: string;
}

export default mixins().extend({
  name: 'SupplierPortalPermissionSectionForm',

  props: {
    source: {
      type: Object as () => Source,
      required: true,
    },
  },

  data() {
    return {
      values: JSON.parse(JSON.stringify(this.source.values)),
    };
  },

  computed: {
    permissionHalfLength(): number {
      return Math.round(this.source.options.length / 2);
    },
  },

  watch: {
    values(values: string[]) {
      this.$emit('update:source', {
        ...this.source,
        values,
      });
    },
  },
});
